import { axios } from "./../plugins/axios";

/**
 * @method 记录明细 - 收入明细
 */
export function getOrder(parameter) {
  return axios.request("", {
    method: "get",
    params: {
      module: "fans",
      action: "order",
      app: "order_status",
      ...parameter
    }
  });
}
