<template>
  <div>
    <van-nav-bar
      title="支付成功"
      left-arrow
      @click-left="$router.push('/data')"
    />
    <img
      src="https://t.yhaocang.com/upload/sf_static/img/success/successx.png"
      alt=""
    />
    <div class="text">{{ txt }}</div>
    <div class="btn-content">
      <div class="home-page" @click="$router.push('/data')">返回首页</div>
      <div class="view_members" @click="$router.push('/myCard')">
        查看会员卡
      </div>
    </div>
  </div>
</template>

<script>
import { getOrder } from "@/api/success";
export default {
  data() {
    return {
      txt: "请稍等系统正在出账~"
    };
  },
  methods: {},
  mounted() {
    let orderId = this.$route.query.order_id;
    getOrder({ order_id: orderId }).then(res => {
      console.log(res);
      if (res.data.code == 200) {
        console.log(res.data.message);
        if (res.data.data.pay_status == 1) {
          this.txt = "支付成功";
        } else {
          this.txt = "支付失败";
        }
      }
    });
  }
};
</script>

<style lang="scss" scoped>
/deep/.van-nav-bar .van-icon {
  color: #333333;
}
img {
  width: 172px;
  height: 160px;
  display: block;
  margin: auto;
  margin-top: 64px;
}
.text {
  font-size: 31px;
  font-weight: 500;
  color: #333333;
  text-align: center;
}
.btn-content {
  display: flex;
  text-align: center;
  font-size: 28px;
  font-weight: 400;
  justify-content: space-around;
  padding: 0 80px;
  margin-top: 50px;
  .home-page {
    width: 249px;
    height: 71px;
    border: 1px solid #999999;
    border-radius: 35px;
    color: #333333;
    line-height: 22px;

    line-height: 71px;
  }
  .view_members {
    width: 249px;
    height: 71px;
    background: #333333;
    border: 1px solid #999999;
    border-radius: 35px;
    color: #ffffff;
    line-height: 71px;
  }
}
</style>
